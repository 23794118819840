<app-spinner></app-spinner>
<p-toast key="globalToast"></p-toast>

<div class="app-wrapper">
  <div class="content-grid">
    <div class="header">
      <app-header></app-header>
    </div>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
</div>
