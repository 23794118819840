import { Component } from '@angular/core';

@Component({
  selector: 'app-contact',
  template: `<div class="contact">
    <ng-content></ng-content>
  </div>`,
  styles: [
    `
      .contact {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 32px;
      }
    `,
  ],
  standalone: true,
})
export class ContactComponent {}
