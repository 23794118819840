export class DateFormatUtility {
  static getDateFormat(period: string): 'shortDate' | "MMMM yyyy" | "yyyy" {
    let currentDateFormat: "shortDate" | "MMMM yyyy" | "yyyy";

    switch (period) {
      case "day":
      case "week":
        currentDateFormat = "shortDate";
        break;
      case "month":
        currentDateFormat = "MMMM yyyy";
        break;
      case "year":
        currentDateFormat = "yyyy";
        break;
      default:
        // Default to a fallback format if the provided period is not recognized
        currentDateFormat = 'shortDate';
        break;
    }

    return currentDateFormat;
  }

  static getDateFormatCode(locale: string): string {

    const testDate = new Date(2030, 11, 20);
    const formattedDate = new Intl.DateTimeFormat(locale).format(testDate);

    const dayRegex = /\b20\b/;
    const monthRegex = /\b12\b/;
    const yearRegex = /\b2030\b/;

    let dateFormat = formattedDate
      .replace(dayRegex, 'dd')
      .replace(monthRegex, 'mm')
      .replace(yearRegex, 'yy');

    dateFormat = dateFormat
      .replace(/\b(\d)\b/, 'd')
      .replace(/\b(\d{1,2})\/(\d{1,2})\/(\d{4})\b/, 'mm/dd/yy');

    return dateFormat;

  }
}
