import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, RouterLink } from '@angular/router';
import { map } from 'rxjs/operators';
import { BreadCrumb } from './BreadCrumb';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink],
})
export class TitleComponent {
  @Input() title: string = '';
  @Input() text: string = '';

  @Input() darkMode: boolean = true;
  @Input() big: boolean = false;
  @Input() useBreadcrumbs: BreadCrumb[] = [];
  @Input() breadcrumbsOnly: boolean = false;

  public breadcrumbs: BreadCrumb[];

  translationService = inject(TranslateService);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.breadcrumbs = [];
    this.router.events.pipe(map((event) => event instanceof NavigationEnd)).subscribe((event) => {
      let root: ActivatedRoute = this.activatedRoute.root;

      if (this.useBreadcrumbs.length > 0) {
        this.breadcrumbs = this.useBreadcrumbs;
      } else {
        this.breadcrumbs = this.getBreadcrumbs(root);
      }
    });
  }

  ngOnInit() {
    this.router.events.pipe(map((event) => event instanceof NavigationEnd)).subscribe((event) => {
      let root: ActivatedRoute = this.activatedRoute.root;

      if (this.useBreadcrumbs.length > 0) {
        this.breadcrumbs = this.useBreadcrumbs;
      } else {
        this.breadcrumbs = this.getBreadcrumbs(root);
      }
    });
  }

  getTranslation(value: string): string {
    if (!value) {
      return value;
    }
    if (value.startsWith(':')) {
      return value;
    }

    let translation = this.translationService.instant(value);
    return translation;
  }

  /**
   * Build breadcrumb
   * Copy from https://plnkr.co/edit/iedQjH?p=preview&preview
   * @param route
   * @param url
   * @param breadcrumbs
   * @private
   */

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

    let children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (let child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      let routeURL = child.snapshot.url.map((segment) => segment.path).join('/');

      url += `/${routeURL}`;

      let breadcrumb: BreadCrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url,
      };

      let pathVariable = child.snapshot.data[ROUTE_DATA_BREADCRUMB] as string;
      if (pathVariable?.startsWith(':')) {
        pathVariable = pathVariable.replace(':', '');

        let label = child.snapshot.params[pathVariable];

        if (label) {
          breadcrumb.label = label;
        }
      }

      if (breadcrumbs.filter((b) => b.label === breadcrumb.label).length === 0) breadcrumbs.push(breadcrumb);

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}
