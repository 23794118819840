import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { BreadCrumb, TitleComponent } from '@myarvato/shared/title';
import { TranslateTextPipe } from '@myarvato/shared/translation';
import { ContactCardComponent, ContactComponent } from '@myarvato/shared/contact';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: true,
    imports: [TitleComponent, NgIf, ContactComponent, ContactCardComponent, TranslateTextPipe, TranslateModule]
})
export class ErrorComponent {

  title: string = '';
  description: string = '';
  content: string = '';
  traceId: string | undefined;
  customBreadcrumb: BreadCrumb[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.activatedRoute.params.subscribe(params => {
      const errorCode = params['errorCode'];
      const traceId = params['traceId']

      if (errorCode)
        forkJoin([
          this.translateService.get(`errors.${errorCode}.title`),
          this.translateService.get(`errors.${errorCode}.description`),
          this.translateService.get(`errors.${errorCode}.content`)
        ]).pipe(
          map(([title, description, content]) => ({

            title: title !== `errors.${errorCode}.title` ?
              title : this.translateService.get('pages.error.page-not-found'),
            description: description !== `errors.${errorCode}.description` ?
              description : this.translateService.get('pages.error.page-not-found-description'),
            content: content !== `errors.${errorCode}.content` ? content : ''
          }))
        ).subscribe(({ title, description, content }) => {
          this.title = title;
          this.description = description;
          this.content = content;

          this.customBreadcrumb?.push({
            label: title,
            url: ''
          })

          if (traceId) {
            this.traceId = traceId;
          }
        });
    });
  }
}
