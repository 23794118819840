import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get the contacts for authorized or unauthorized user
   * @returns A list of contacts
   */
  getContacts(loggedIn: boolean): Observable<any> {
    if (loggedIn) {
      return this.httpClient.get<any>('/assets/api/contacts/authorized.json', { responseType: 'json' });
    }

    return this.httpClient.get<any>('/assets/api/contacts/unauthorized.json', { responseType: 'json' });
  }
}
