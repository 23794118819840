import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MeDto } from '../models/MeDto';
import { UpdateMeDto } from '../models/UpdateMeDto';

@Injectable({
  providedIn: 'root',
})
export class MyAccountService {
  private readonly url = environment.url.api + 'user/me';
  private readonly httpClient = inject(HttpClient);

  public getUserData(): Observable<MeDto> {
    return this.httpClient.get<MeDto>(this.url);
  }

  public changeUserInformation(data: UpdateMeDto): Observable<MeDto> {
    return this.httpClient.post<MeDto>(this.url, data);
  }
}
