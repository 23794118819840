import { Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Language } from '../store/Language';
import { Observable, Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthStoreService, ClientSettings } from '@myarvato/shared/store';
import { AuthorizationService, ClientService } from '@myarvato/shared/services';
import { CXC_AUTH_MODULES } from '@myarvato/cxc/shared/utilities';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgClass, TranslateModule],
})
export class HeaderComponent implements OnInit, OnDestroy {
  setLanguage(arg0: string) {
    localStorage.setItem('language', arg0);
    window.location.reload();
  }

  isLoggedIn: boolean = false;
  userName: string = '';
  displayName: string = '';
  client: ClientSettings | undefined;

  translateService = inject(TranslateService);
  clients: ClientSettings[] = [];

  clientsObservable: Observable<ClientSettings[]> | undefined;
  authSubscription: Subscription | undefined;
  selectedLanguage = signal<Language>({ code: 'en', language: this.translateService.instant('core.header.languages.english') });

  languages = signal<Language[]>([{ code: 'en', language: this.translateService.instant('core.header.languages.english') }]);

  protected readonly isCxcUser = computed(() => this.authorizationService.hasModule(CXC_AUTH_MODULES));

  constructor(
    private authStoreService: AuthStoreService,
    protected authorizationService: AuthorizationService,
    private clientService: ClientService,
  ) {}

  ngOnInit(): void {
    this.client = this.clientService.getClientSettingsFromStorage();

    this.initLanguages();
    this.authSubscription = this.authStoreService.authSate$.subscribe((authStore) => {
      this.isLoggedIn = authStore.isLoggedIn;
      this.userName = authStore.userName ?? '';
      this.displayName = authStore.displayName ?? '';
      this.client = this.clientService.getClientSettingsFromStorage();

      /*
         If doesn't msal redirect on page load because of an invalid token
         */
      if (this.isLoggedIn) {
        this.loadClients();
        this.addTemplate();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  addTemplate() {
    let currentLanguage = localStorage.getItem('language') ?? 'en';

    if (this.authorizationService.hasRoles(['my-admin']) && currentLanguage != 'template') {
      if (!this.languages().filter((n) => n.code == 'template')[0]) {
        this.languages().push({ code: 'template', language: this.translateService.instant('core.header.languages.template') });
      }
    }
  }

  initLanguages(): void {
    let currentLanguage = localStorage.getItem('language') ?? 'en';

    if (currentLanguage == 'template' && this.authorizationService.hasRoles(['my-admin'])) {
      this.selectedLanguage.set({ code: 'template', language: this.translateService.instant('core.header.languages.template') });
      return;
    }
    if (currentLanguage == 'template') {
      currentLanguage = 'en';
      localStorage.setItem('language', currentLanguage);
    }

    this.selectedLanguage.set(this.languages().filter((n) => n.code == currentLanguage)[0]);
    this.languages.set(this.languages().filter((n) => n.code != currentLanguage));
  }

  getDisplayName(): string {
    if (this.displayName == '') {
      return '';
    }

    let split = this.displayName.split(',');
    if (split.length == 1) {
      return this.displayName;
    }
    return split[1] + ' ' + split[0];
  }

  loadClients() {
    if (!this.clientsObservable) {
      this.clientsObservable = this.clientService.getClients();
      this.clientsObservable.subscribe({
        next: (clients: ClientSettings[]) => {
          this.clients = clients;

          if (this.clientService.getClientSettingsFromStorage()) {
            let client = clients.find((c) => c.client == this.clientService.getClientSettingsFromStorage()?.client);

            if (client) {
              this.authStoreService.setClientState(client);
              this.client = client;
              return;
            }
          }

          this.authStoreService.setClientState(clients[0]);
          this.client = clients[0];
        },
        error: () => {
          this.clients = [];
          this.client = undefined;

          this.authStoreService.setDisplayNameState('');
        },
      });
    }
  }

  switchClient(client: ClientSettings) {
    this.authStoreService.setClientState(client);
    window.location.reload();
  }

  get hasOneClients(): boolean {
    return this.clients?.length === 1;
  }

  get hasMultipleClients(): boolean {
    return this.clients?.length > 1;
  }
}
