import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpEvent } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor() {}

  /**
   * Always map object into body.response
   * @param req
   * @param next
   */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (req.headers.get('skip')) return event;

        if (event instanceof HttpResponse) {
          event = event.clone({ body: event.body.response });
        }
        return event;
      }),
    );
  }
}
