import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  standalone: true,
})
export class ContactCardComponent {
  @Input() name: string = '';
  @Input() position: string = '';
  @Input() image: string = '';
  @Input() email: string = '';
}
