import { Component, Input } from '@angular/core';

@Component({
  selector: 'arvato-button',
  template: `<button class="arvato-button {{ state }}" [disabled]="disabled">
    <ng-content></ng-content>
  </button>`,
  styleUrls: ['./button.component.scss'],
  standalone: true,
})
export class ButtonComponent {
  @Input() state: string = 'primary';
  @Input() disabled: boolean = false;
}
