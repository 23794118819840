<app-title [big]="true" title="Privacy Policy" [darkMode]="false"> </app-title>

<div class="container container--xl mt--64 mb--128">
  <div class="row">
    <div class="col-xl-12 col-lg-8 fs--scale">
      <section>
        <p>{{ titleDescription }}</p>
        <br />
      </section>
      <section>
        <h2>1. Who is responsible for processing my data?</h2>
        <p>Arvato SE</p>
        <p>Reinhard-Mohn-Strasse 22</p>
        <p>33333 Gütersloh</p>
        <br />
        <p>Phone +49 5241 80-70136 <br /></p>

        <p>
          <a href="mailto:MyArvato@teams.arvato-scs.com"> E-mail contact</a>
          <br />
          <a href="https://arvato.com/"><span lang="SV">https://arvato.com</span></a>
          <br />
        </p>
        <br />
        <p>
          is responsible for the processing of your data on the website. You can reach the data protection officer of Arvato SE at the above
          postal address with the addition &quot;To the data protection officer&quot; or at the e-mail address
          data-privacy&#64;arvato-supply-chain.com. Arvato SE (hereinafter referred to as &quot;we&quot; or &quot;us&quot;) process personal
          data in accordance with the provisions of the GDPR and the German Federal Data Protection Act (hereinafter referred to as
          &quot;BDSG&quot;).
        </p>
      </section>
      <section>
        <h2>2. What data is collected?</h2>
        <p>
          In the context of your visit to the website and exclusively for technical, contractual or legal requirements, your data is
          processed in order to display the desired content and enable you to use the offers.
        </p>
        <br />
        <p>
          When you visit the website, information is automatically collected by the accessing computer (hereinafter referred to as
          &quot;access data&quot;). This access data includes details about the content you requested on the website and your usage
          behavior, as well as information about the browser type and version, the operating system, the Internet service provider, the date
          and time of use of the website, the previously visited web pages and newly accessed web pages via the website and the IP address
          of the computer (also called &quot;server log files&quot;). Statistical data is created and evaluated from the access data with
          the help of web tracking. However, it is not possible for us to draw conclusions about your person.
        </p>
        <br />
        <p>
          To enable login to the website, a guest user is created in Arvato's Microsoft Azure environment, which is passed on to the website
          together with the login authorization.
        </p>
        <p>
          The provision of your data (name and professional e-mail address) is required for the use of the website. Mandatory data are
          regularly marked with *.
        </p>
      </section>
      <section>
        <h2>3. Which cookies are used?</h2>
        <p>
          Cookies are used on the website. Cookies are small text files that are stored on your computer when you visit a website. The
          stored cookies are assigned to the browser you are using. If the corresponding website is called up again, the web browser sends
          back the content of the cookies and thus enables recognition of the user. Cookies are limited to a runtime (e.g. expiration of the
          browser session). In addition, you can configure and delete the cookies according to your wishes via the settings of your browser.
          However, premature deletion may result in limited availability of the website's offers.
        </p>
        <br />
        <p>
          The website distinguishes between two types of cookies: function-related cookies and optional cookies. The function-related
          cookies are used to provide the website in a technically and functionally flawless manner (e.g. identification and authentication
          of the user, language settings, display of image recordings). Without the function-related cookies, the offers of the website are
          only available to a limited extent. The optional cookies help to optimize the offers on the website by recording user behavior and
          evaluating it in statistical form.
        </p>
        <br />
        <p>
          In principle, cookies are only an online identifier without reference to a person. Cookies may be personally identifiable if the
          information generated by the cookies is merged with other data, such as the access data pursuant to Section 2 (e.g., when web
          tracking is used). In principle, such a combination only takes place in pseudonymized form. Personal data may only be merged if
          you have consented to it or if the law permits it.
        </p>
      </section>
      <section>
        <h2>4. What data is collected and for what purposes?</h2>
        <p>
          The purposes of data processing may result from technical, contractual or legal requirements and, if applicable, from consent. We
          use your datap for the following purposes:
        </p>
        <ul>
          <li>
            <p>
              To provide the Website and ensure technical security, in particular to correct technical errors and to ensure that
              unauthorized persons do not gain access to the Website's systems
            </p>
          </li>
          <li>
            <p>Reach measurements and web analytics to make the website more efficient and interesting for you;</p>
          </li>
          <li>
            <p>Communication and Customer Care ;</p>
          </li>
          <li>
            <p>Processing your data subject rights;</p>
          </li>
          <li>
            <p>Traceability of user actions (auditability)</p>
          </li>
          <li>
            <p>Provision of downloads and reports.</p>
          </li>
        </ul>
        <p>Information on these data processing purposes can be found in the following sections of this privacy notice.</p>
      </section>

      <h3>4.1 Technical provision of the website</h3>

      <section>
        <h4>4.1.1 Description and scope of data processing</h4>
        <p>
          For the functioning of the website, the performance of security analyses and the defense against attacks, the server log files are
          automatically recorded and stored for a short period of time by the computer system of the calling computer as part of the access
          data according to section 2 when entering and during the use of the website. The server log files are not stored together with
          other data. We use the server log files for statistical evaluations in ordedr to analyze and rectify technical faults, to ward off
          attempted attacks and fraud and to optimize the functionality of the website.
        </p>
      </section>
      <section>
        <h4>4.1.2 Purposes and legal basis of data processing</h4>
        <p>
          The legal basis for the collection of server log files is Art. 6 (1) f GDPR. The legitimate interests lie in the functionality of
          the website, the performance of security analyses and the defense against dangers.
        </p>
      </section>
      <section>
        <h4>4.1.3 Duration of storage or criteria for determining this duration</h4>
        <p>
          Once the web pages have been called up, the server log files are stored on the web server and the IP address contained therein is
          deleted after 90 days at the latest in accordance with the requirements of the German Telecommunications Act (&quot;TKG&quot;).
          This does not apply if there is a concrete suspicion of a crime and further storage for the purpose of evaluation and
          clarification is required by law.
        </p>
      </section>
      <section>
        <h4>4.1.4 Right of objection and elimination</h4>
        <p>
          You have the right to object to the processing of your data if there are reasons for doing so that arise from your particular
          situation. If you would like to exercise your right to object, please contact the contact address given in section 1.
        </p>
      </section>

      <h3>4.2 Contact form</h3>

      <section>
        <h4>4.2.1 Description and scope of data processing</h4>
        <p>
          The website provides the option of contacting us via a contact form. If you take advantage of this option, you provide us with
          your data such as title, last name, e-mail address, and your specific request (also called &quot;contact data&quot;) and
          optionally your first name and telephone number. The contact data will only be stored and used to process the request of your
          contact (e.g. questions about the products and services).
        </p>
      </section>
      <section>
        <h4>4.2.2 Purposes and legal basis of data processing</h4>
        <p>
          The legal basis for processing your contact data is regularly Art. 6 (1) f GDPR. The legitimate interests lie in the processing of
          your request as well as in ensuring audit security and further communication.
        </p>
      </section>
      <section>
        <h4>4.2.3 Duration of storage or criteria for determining this</h4>
        <p>
          After your request has been processed and further communication has ended, your contact data will be deleted. For this purpose,
          the data will be stored until the contractual and/or legal retention periods (currently 6 to 10 years) are fulfilled.
        </p>
      </section>
      <section>
        <h4>4.2.4 Possibilities of objection and removal</h4>
        <p>
          You have the right to object to the processing of your contact data, insofar as there are reasons for doing so that arise from
          your particular situation. If you would like to exercise your right to object, please contact the contact address given in section
          1. If you object, the processing of your request cannot be continued.
        </p>
      </section>

      <h3>4.3 Assertion of data subject rights</h3>

      <section>
        <h4>4.3.1 Description and scope of data processing</h4>
        <p>
          Through this data protection notice, we draw your attention to the possibility of asserting data subject rights under data
          protection law, such as information. <span>In order to</span> be able to answer your inquiry comprehensively, we are required by
          law to verify your identity. This is to protect your data from unauthorized access. For this purpose, it may be necessary for you
          to provide us with a passport copy or similar information about yourself as well as about the individual data processing. In this
          provided information, you may omit information that is not necessary to prove your identity (for example, on a passport copy, eye
          color, height or nationality may be blacked out). Without any provision, we will not be able to comply with your data subject
          rights.
        </p>
      </section>
      <section>
        <h4>4.3.2 Purposes and legal basis of data processing</h4>
        <p>
          We are legally obliged to process your data for the assertion of data subject rights according to the GDPR. The legal basis for
          this is Art. 6 (1) c GDPR.
        </p>
      </section>
      <section>
        <h4>4.3.2 Duration of storage or criteria for determining this duration</h4>
        <p>
          We store the correspondence conducted with you in relation to the assertion of data subject rights for a period of 3 years. This
          does not include proof of identity, such as a copy of your ID card, if you have provided us with one. This will be deleted once
          your identity has been established.
        </p>
      </section>
      <section>
        <h4>4.3.3 Possibilities of objection and elimination</h4>

        <p>
          The processing of your data is necessary for the fulfillment of your data subject rights. In this respect, you have no right of
          objection.
        </p>
      </section>

      <h3>4.4 Download of free content (e.g. studies, whitepapers)</h3>

      <section>
        <h4>4.4.1 Description and scope of data processing</h4>
        <p>
          Via the website, you can download content provided by us, such as studies or white papers on current topics from our business
          areas. By registering on the website, your data is transmitted to us and stored in the event of a download. In addition, we also
          record the date and time of your download. The download is voluntary, free of charge and generally independent of any other offers
          on the website.
        </p>
      </section>
      <section>
        <h4>4.4.2 Purposes and legal basis of data processing</h4>
        <p>
          The download of free content represents an online offer from us to you. The legal basis for processing your data collected in this
          regard is our legitimate interest Art. 6 (1) f GDPR. The legitimate interest lies in the further development of the offer.
        </p>
      </section>
      <section>
        <h4>4.4.3 Duration of storage or criteria for determining this duration</h4>
        <p>Your data will be stored for 90 days. After that, your data will be deleted, unless we are required by law to store it.</p>
      </section>
      <section>
        <h4>4.4.4 Options for objection and elimination</h4>
        <p>
          You have the right to object to the processing of your data, insofar as there are grounds for doing so that arise from your
          <span>particular situation</span>. If you wish to exercise your right of objection, please contact the contact address given in
          section 1.
        </p>
      </section>

      <h3>4.5 Registration for events and webinars (online events)</h3>

      <section>
        <h4>4.5.1 Description and scope of data processing</h4>
        <p>
          You can register for individual events via the website. The events usually consist of presentations on current topics and
          developments in our business areas. These can take place <br />
          in rooms provided by us as well as a digital offering (e.g. webinar). Our portfolio of services and new products and services can
          also be presented. Via the registration form, your master data (title, first name, last name, company, company), contact data
          (business e-mail address), date of participation as well as information about your participation in the dinner are transmitted to
          us and further processed for your participation. We also process information about your participation in the social program,
          dietary information, as well as information about the need for accommodation and/ or a hotel, if you optionally provide it to us.
          In doing so, we also process the date and time of your registration. We send information e-mails to the contact details you have
          provided to inform you about (e.g. specifications of the event, updates) the respective event. Your data will only be passed on to
          third parties (e.g. organizer, hotel) if this is necessary for your participation in the event.
        </p>
      </section>
      <section>
        <h4>4.5.2 Purposes and legal basis of data processing</h4>
        <p>
          We process your data for the organization and implementation of the event we organize. Participation in events represents a
          digital offer from us to you. The legal basis for the processing of your data collected in the context of section 4.5.1 is the
          performance of the contract pursuant to Art. 6 (1) b GDPR.
        </p>
      </section>
      <section>
        <h4>4.5.3 Duration of storage or criteria for determining this duration</h4>
        <p>
          Your data will be stored for as long as it is required for participation in the individual events. After your participation, your
          data will be stored until the contractual and/or legal retention periods (currently 6 to 10 years) are fulfilled. If we are unable
          to send you a confirmation of participation due to our limited contingent of participants, the purpose for further processing of
          your data no longer applies and your data will be deleted within a period of three months, provided that the deletion does not
          conflict with any statutory retention periods.
        </p>
      </section>
      <section>
        <h4>4.5.4 Possibilities of objection and elimination</h4>
        <p>
          The processing of your data is necessary for the participation of the individual event. In this respect, you have no right of
          objection.
        </p>
      </section>

      <h3>4.6 Business customer survey</h3>

      <section>
        <h4>4.6.1 Description and scope of data processing</h4>

        <p>
          As part of our business customer relationships, we occasionally conduct customer surveys to record satisfaction and improve our
          services. The customer survey is aimed at our business customers, however, we regularly have your contact details stored in our
          customer database if you are a contact person. We use your contact data to invite you to participate in the customer survey.
          Participation is voluntary and you have the option to communicate to us at any time that you no longer wish to receive invitations
          to participate in customer surveys in the future.
        </p>
      </section>
      <section>
        <h4>4.6.2 Purpose and legal basis of data processing</h4>
        <p>
          The legal basis for processing your contact data is Art. 6 (1) f GDPR. The legitimate interests lie in customer care and customer
          loyalty as well as the improvement of our services.
        </p>
      </section>
      <section>
        <h4>4.6.3 Duration of storage or criteria for determining the duration</h4>
        <p>
          principle, the survey results are stored for the duration of the business relationship. This does not apply if the survey results
          consist of purely statistical data.
        </p>
      </section>
      <section>
        <h4>4.6.4 Options for objection and removal</h4>
        <p>
          You have the right to object to the processing of your contact data, insofar as there are grounds for doing so that arise from
          your
          <span>particular situation</span>. If you would like to exercise your right to object, please contact the contact address given in
          section 1.
        </p>
      </section>
      <section>
        <h3>4.8 Web tracking</h3>
        <p>
          The website includes services that optimize the user experience and measure the reach of the website. In the process, your access
          data is recorded in accordance with section 2 and usage behavior is evaluated with the aid of cookies in accordance with section
          3. Personal identification is generally not required for web tracking. The IP address contained in your access data is either not
          used or only used in abbreviated form, and thus only pseudonymous usage profiles are created. As a matter of principle, these are
          not merged with other <span>data</span> and you have the option of revoking this at any time. Personal usage profiles are only
          created in exceptional cases and if you have given your consent.
        </p>
        <br />
        <p>
          The web tracking services are regularly provided by service providers who process the usage profiles according to our instructions
          and not for their own purposes. This is ensured by means of data processing agreements. If the service providers are established
          outside the European Union or the European Economic Area (hereinafter referred to as &quot;EU / EEA&quot;), a so-called third
          country transfer takes place. This is permissible if you have consented to it, we have created a guarantee for a level of data
          protection appropriate to the European standard or the EU Commission has classified the respective third country as a safe third
          country. The third country transfer of the respective service is marked below. Further information on the recipients of your data
          and the third country transfer can be found in section 6 and section 7.
        </p>
      </section>
      <section>
        <h4>4.8.1 Matomo</h4>
        <p>
          The website uses the service Matomo. The provider of Matomo is InnoCraft Ltd. headquartered at: 7 Waterloo Quay PO625, 6140
          Wellington, New Zealand (hereinafter referred to as &quot; InnoCraft&quot;). This website uses Matomo in a self-hosted
          environment. As a result, the data collected is processed exclusively by Arvato and is not disclosed to InnoCraft at any time. The
          following is stored in Matomo:
        </p>
        <ul>
          <li>
            <p>Two bytes of the IP address of the requesting system (e.g. 202.104.xxx.xxx)</p>
          </li>
          <li>
            <p>The (order) of the called <span>URLs</span></p>
          </li>
          <li>
            <p>Triggered actions</p>
          </li>
          <p>Time and date of a visit</p>
          <li>
            <p>Dwell time on a URL</p>
          </li>
          <li>
            <p>Duration of the entire visit</p>
          </li>
          <li>
            <p>Date and time in the user's local time zone</p>
          </li>
          <li>
            <p>Files that have been clicked and downloaded</p>
          </li>
          <li>
            <p>
              Page generation time (the time it takes to generate web pages from the web server and then download them from the user: Page
              speed)
            </p>
          </li>
          <li>
            <p>Main language of the used browser (browser agent header)</p>
          </li>
          <li>
            <p>User agent of the browser, browser version, device used and operating system (user agent header).</p>
          </li>
        </ul>
        <p>
          The IP addresses are anonymized (IP masking), so that an assignment to individual users is not possible. The statistical data is
          evaluated after transmission for the purpose of optimizing user-friendliness and improving customer support. The data collected is
          not passed on to third parties.
        </p>
      </section>
      <section>
        <h4>4.8.5 Purposes and legal basis of data processing</h4>
        <p>
          The legal basis for the collection and analysis of pseudonymous usage profiles is Art. 6 (1) f GDPR / § 15 (3)
          <span>Telemediengesetz</span> (TMG). Our legitimate interests lie in optimizing the user-friendliness of the website and measuring
          the reach.
        </p>
      </section>
      <section>
        <h4>4.8.6 Duration of storage or criteria for determining this duration</h4>
        <p>
          The data that is collected and evaluated when using the web tracking services is generally stored until you object to its use. The
          storage period of the log data generated for
          <span>Matomo</span> is usually a maximum of 180 days.
        </p>
      </section>
      <section>
        <h4>4.8.7 Opt-out and removal options</h4>
        <p>
          Since we do not currently collect any personal or personally identifiable data through <span>Matomo</span>, there is no
          possibility for an objection, as we could not relate this to specific data. You may deactivate web tracking by all providers that
          are part of the &quot;Your Online Choices&quot; self-regulation campaign centrally via the link
          <a href="http://www.youronlinechoices.eu/" target="_blank" style="color: #0779b5">http://www.youronlinechoices.eu.</a>
        </p>
      </section>
      <section>
        <h2>5.External services and content</h2>
        <p>
          We integrate external services and content on our website. When you use such a service or when third-party content is displayed to
          you, communication data is exchanged between you and the respective provider for technical reasons.
        </p>
        <br />
        <p>
          In addition, the provider of the respective services or content may process your data for further, own purposes. We have
          configured services and content from providers known to process data for their own purposes to the best of our knowledge and
          belief so that either communication for purposes other than the presentation of the content or services on our website does not
          take place, or communication only takes place when you actively decide to use the service. However, since we have no influence on
          the data collected by third parties and its processing by them, we cannot make any binding statements about the purpose and scope
          of the processing of your data.
        </p>

        <br />
        <p>
          For further information about the purpose and scope of the collection and processing of your data, please refer to the data
          protection notices of the respective providers of the services and content we integrate who are responsible for data protection:
        </p>
        <ul>
          <li>
            <em>LinkedIn: </em
            ><span style="color: windowtext"
              ><a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
                ><em><span>www.linkedin.com/legal/privacy-policy</span></em></a
              ></span
            >
          </li>
          <li>
            <em>YouTube:</em>
            <a href="https://support.google.com/youtube/answer/7671399" target="_blank"
              ><em><span>https://support.google.com/youtube/answer/7671399</span></em></a
            >
          </li>
          <li>
            <em>Google Maps:</em>
            <a href="https://maps.google.com/help/terms_maps.html" target="_blank"
              ><em><span>https://maps.google.com/help/terms_maps.html</span></em></a
            >
          </li>
        </ul>
      </section>
      <section>
        <h2>6. Who receives my data?</h2>

        <p>
          We provide access to your data to those departments that need it to fulfill the purposes described in section 4. The service
          providers we use may also have access to your data (so-called &quot;order processors&quot;). Data processing agreements ensure
          that these service providers are bound by instructions, data security and the confidential handling of your data.
        </p>

        <br />
        <p>
          A transfer of data to further recipients such as advertising partners, providers of social media services or law enforcement
          authorities (so-called &quot;third parties&quot;) will take place if required by law or if you have consented. In connection with
          the receipt of our newsletter, you have consented to your data also being passed on to the subsidiaries of our
          <a href="https://arvato.com/gesellschaften-der-unternehmensgruppe" target="_blank">group of companies</a> for the purpose of
          individual advertising. Internal data protection guidelines and joint data security management ensure confidential handling of
          your data and compliance with data protection regulations.
        </p>
      </section>
      <section>
        <h2>7. Will my data be processed in a third country?</h2>
        <p>
          Insofar as the service providers and / or third parties mentioned in section 6 are established outside the EU / EEA, this may
          result in your data being transferred to a country where no level of data protection adequate to the EU / EEA can be guaranteed.
          However, such a level of data protection can be ensured with an appropriate guarantee. Standard contractual clauses provided by
          the EU Commission can be considered as an appropriate guarantee. Any guarantees may be waived by way of exception, for example, if
          you consent or the third country transfer is necessary for the fulfillment of our contractual relationship. Also, the EU
          Commission has recognized certain third countries as safe third countries, so appropriate safeguards on the part of the company
          may also be waived at this point. Some of our <a href="https://arvato.com/gesellschaften-der-unternehmensgruppe">subsidiaries</a>
          are located outside the EU / EEA (e.g. USA or Shanghai). We have concluded standard contractual clauses with these subsidiaries in
          accordance with the requirements of the EU Commission. These contractual clauses can be found here
          https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2004:385:0074:0084:DE:PDF.
        </p>
        <br />
        <p>
          No third country transfer takes place for the website. Data storage takes place in an Arvato-hosted environment within the
          European Union.
        </p>
      </section>
      <section>
        <h2>8. What data protection rights do i have?</h2>
        <p>
          You have the right to obtain information about the personal data we have stored about you at any time. If data about your person
          is incorrect or no longer up to date, you have the right to request its correction. You also have the right to request the
          deletion or restriction of the processing of your data in accordance with Art. 17 or Art. 18 GDPR. You may also have the right to
          receive the data you have provided in a common and machine-readable format (so-called &quot;right to data portability&quot;).
        </p>
        <br />
        <p>
          If you have given your consent to the processing of personal data for specific purposes, you may revoke this consent at any time
          with effect for the future. The revocation is to be addressed to the company at the contact address mentioned under item 1.
        </p>
        <br />
        <p>
          <strong>
            In accordance with Art. 21 GDPR, you have the right to object at any time, on grounds relating to your particular situation, to
            the processing of your data which is carried out on the legal basis of Art. 6 (1) f GDPR. You also have the right to object at
            any time to the processing of your personal data for the purpose of direct marketing. The same applies to the automated
            procedures when using individual cookies, unless they are absolutely necessary for the provision of the website.</strong
          >
        </p>
        <br />
        <p>
          In addition, you have the option of contacting a data protection authority and filing a complaint there. The competent authority
          is the
        </p>
        <br />
        <p>State Commissioner for Data Protection and Freedom of Information of North Rhine-Westphalia</p>
        <p>Kavalleriestr. 2-4</p>
        <p>40213 Düsseldorf</p>
        <p>Phone: 0211/38424-0</p>
        <p>Fax: 0211/38424-10</p>
        <p>E-mail: poststelle&#64;ldi.nrw.de.</p>
        <br />
        <p>However, you can also contact the data protection authority responsible for your place of residence.</p>
      </section>
    </div>
  </div>
</div>
