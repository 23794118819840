import { ContactsService } from '../auth/contacts.service';
import { Component, effect } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextPipe } from '@myarvato/shared/translation';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgFor } from '@angular/common';
import { AuthStoreService } from '@myarvato/shared/store';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [NgFor, RouterLink, AsyncPipe, TranslateTextPipe, TranslateModule],
})
export class FooterComponent {
  isLoggedIn: boolean = false;
  currentYear = new Date().getFullYear();
  versionNumber: string = require('../../../../package.json').version + '.' + require('../../../../variables.json').buildNumber;
  contacts: Observable<any> | undefined;

  constructor(
    private authStoreService: AuthStoreService,
    private footerService: ContactsService,
  ) {}

  // handle signal effect on loggedInState
  handleEffect = effect(() => {
    let loggedIn = this.authStoreService.getLoggedInState()();
    this.contacts = this.footerService.getContacts(loggedIn);
  });

  ngOnInit(): void {
    this.authStoreService.authSate$.subscribe((authStore) => {
      this.isLoggedIn = authStore.isLoggedIn;
    });
  }
}
