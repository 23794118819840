import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from '@myarvato/shared/services';

@Injectable()
export class ModuleGuard {
  constructor(
    private authenticationResult: AuthorizationService,
    private router: Router,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authenticationResult.isLoggedIn) {
      this.router.navigate(['/auth']);
      return false;
    }

    let result = this.authenticationResult.hasModule(route.data['module']);

    if (!result) {
      this.router.navigate(['/home']);
    }

    return result;
  }
}
