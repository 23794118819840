import { Component } from '@angular/core';
import { TitleComponent } from '@myarvato/shared/title';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  standalone: true,
  imports: [TitleComponent],
})
export class PrivacyComponent {
  titleDescription: string =
    'The data protection information fulfills the information obligations according to the requirements of Art. 12 et seq. of the EU General Data Protection Regulation (hereinafter referred to as "GDPR") and gives you an overview of the processing of your personal data (hereinafter referred to as "data") on the MY.ARVATO.COM website (hereinafter referred to as "website").';
}
