import { InjectionToken } from '@angular/core';

export interface EnvironmentConfig {
  production: boolean;
  url: {
    api: string;
    lang: string;
    cxcCarrierIcons: string;
  };
  auth: {
    clientId: string;
    redirectUri: string;
    authority: string;
    apiScope: string;
  };
  localStorage: {
    client: string;
  };
  tracking: {
    disabled: boolean;
    trackerUrl: string;
    scriptUrl: string;
    siteId: string;
    acceptDoNotTrack: boolean;
    enableLinkTracking: boolean;
    addUserInformation: boolean;
    userTypeCustomDimensionId: number;
  };
}

export const MyArvatoEnvironment = new InjectionToken<EnvironmentConfig>('environment');
