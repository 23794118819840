<section [ngClass]="{ 'bg-dark text-light': darkMode === true, big: big }">
  <div class="title container container--xl mb-5" [ngClass]="{ title: breadcrumbsOnly === false }">
    <nav>
      <ul class="breadcrumb">
        <li *ngIf="breadcrumbs && breadcrumbs.length > 0">Home</li>
        <li *ngFor="let breadcrumb of breadcrumbs">
          <em class="pi pi-angle-right"></em>
          <a [routerLink]="breadcrumb.url">{{ getTranslation(breadcrumb.label) }}</a>
        </li>
      </ul>
    </nav>
    @if (!breadcrumbsOnly) {
      <h4>{{ title }}</h4>

      <p [innerHtml]="text"></p>
      <ng-content></ng-content>
    }
  </div>
</section>
