import { inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';
import { DateFormatUtility } from 'src/app/modules/cxc/cxc.insights/shared/utilities/date-format.utility';
import { MyAccountService } from 'src/app/modules/setting/services/my-account.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService extends String {
  service = inject(MyAccountService);
  translateService = inject(TranslateService);

  private locale: string = 'de-DE';
  localeDateFormatSig = signal<string>('dd.mm.yy');

  initialLoad: boolean = false;
  constructor() {
    super();
    this.getLocalSubscribe().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  /**
   * Gets the user culture Info.
   * @returns CultureInfo
   */
  getLocalSubscribe(): Observable<string> {
    if (!this.initialLoad) {
      return this.service.getUserData().pipe(
        map((n) => {
          if (n.culture) {
            this.initialLoad = true;
            this.setLocale(n.culture);
            return n.culture;
          }

          return this.locale;
        }),
      );
    }
    return of(this.locale);
  }

  override toString(): string {
    return this.getLocale();
  }

  override valueOf(): string {
    return this.toString();
  }

  /**
   * Gets the current locale.
   * @returns locale
   */
  getLocale(): string {
    return this.locale;
  }

  /**
   * Sets the current locale
   * @param locale culture info
   */
  setLocale(locale: string) {
    if (locale) {
      this.locale = locale;
      this.localeDateFormatSig.set(DateFormatUtility.getDateFormatCode(this.locale));

      dayjs.locale(locale.slice(0, 2));
    }
  }
}
