import { EnvironmentConfig } from '@myarvato/shared/models';

export const environment: EnvironmentConfig = {
  production: false,
  url: {
    api: 'https://de-01-myarvato-backend-dev-wa.azurewebsites.net/api/v1/',
    lang: '/assets/api/lang/',
  },
  auth: {
    clientId: '52ce9848-2dc8-4ce4-b5e4-7805ea7f843e',
    redirectUri: 'https://dev-my.arvato.com/auth',
    authority: 'https://login.microsoftonline.com/659c3a90-ec90-4772-b772-cebe2050a82e',
    apiScope: '52ce9848-2dc8-4ce4-b5e4-7805ea7f843e/.default',
  },
  localStorage: {
    client: 'dev-my.arvato.client',
  },
  tracking: {
    disabled: false,
    trackerUrl: 'https://tracker.apps-test.arvato-scs.digital/',
    scriptUrl: 'https://tracker.apps-test.arvato-scs.digital/matomo.js',
    siteId: '7',
    acceptDoNotTrack: true,
    enableLinkTracking: true,
    addUserInformation: true,
    userTypeCustomDimensionId: 2,
  },
};
