import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@myarvato/shared/button';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [NgClass, ButtonComponent, TranslateModule],
})
export class LoginComponent {
  isLoggedIn: boolean = false;
  backgroundImageClass = 'bg-2';

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.backgroundImageClass = 'bg-' + Math.floor(Math.random() * 6);
  }
}
