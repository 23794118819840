import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  private readonly translateService = inject(TranslateService);
  private readonly title = inject(Title);

  updateTitle(snapshot: RouterStateSnapshot): void {
    // resolve the tab title via the native angular way
    const title = super.buildTitle(snapshot);
    if (!title) {
      return;
    }

    // the translation returns the input string if no localization is found
    this.title.setTitle(this.translateService.instant(title));
  }
}
