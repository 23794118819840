import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, filter, of, switchMap } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';

@Injectable()
export class AuthGuard {
  constructor(
    private msalService: MsalService,

    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap(() => {
        if (this.msalService.instance.getAllAccounts().length > 0) {
          return of(true);
        }

        this.router.navigate(['/auth']);
        return of(false);
      }),
    );
  }
}
