import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styles: [],
  standalone: true,
})
export class LogoutComponent {
  constructor(public authService: AuthService) {
    localStorage.removeItem(environment.localStorage.client);
    sessionStorage.removeItem(environment.localStorage.client);
    sessionStorage.removeItem('brand');
    authService.logout();
  }
}
