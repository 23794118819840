import { Injectable, signal } from '@angular/core';
import { AuthStoreService, ClientSettings } from '@myarvato/shared/store';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  clientSetting: ClientSettings | undefined;
  clientSettingSig = signal<ClientSettings | undefined>(undefined);

  constructor(
    private authService: AuthStoreService,
    private clientService: ClientService,
  ) {}

  /**
   * Checks if the current client has all the specified modules.
   *
   * @param modules - An array of module names to check.
   * @returns A boolean indicating whether the client has all the specified modules.
   */
  hasModule(modules: string[]): boolean {
    let clientSetting = this.getClientSettings();

    for (const entry of modules) {
      if (clientSetting.modules?.includes(entry)) {
        return true;
      }
    }
    return false;
  }

  public hasPromoModule(module: string): boolean {
    const clientSetting = this.getClientSettings();
    return clientSetting.promotion?.includes(module) ?? false;
  }

  /**
   * Retrieves the modules from the client settings.
   * @returns An array of strings representing the modules.
   */
  getModules(): string[] {
    return this.getClientSettings().modules ?? [];
  }

  /**
   * Checks if the user is currently logged in.
   * @returns {boolean} True if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    let client = this.authService.getAuthState();
    return client.isLoggedIn;
  }

  /**
   * Checks if the user has a specified role.
   *
   * @param role - The role to check.
   * @returns `true` if the user has the specified role, `false` otherwise.
   */
  hasRole(role: string): boolean {
    return this.hasRoles([role]);
  }

  /**
   * Checks if the user has any of the specified roles.
   *
   * @param roles - An array of roles to check.
   * @returns `true` if the user has any of the specified roles, `false` otherwise.
   */
  hasRoles(roles: string[]): boolean {
    let clientSetting = this.getClientSettings();
    let hasModules = clientSetting.roles?.filter((value: string) => roles.includes(value));

    if (hasModules && hasModules.length > 0) {
      return true;
    }

    return false;
  }

  /**
   * Checks if the user has all the specified role.
   *
   * @param roles - An array of roles to check.
   * @returns `true` if the user has all the specified role, `false` otherwise.
   */
  hasAllRoles(roles: string[]): boolean {
    let clientSetting = this.getClientSettings();
    let hasModules = clientSetting.roles?.filter((value: string) => roles.includes(value));

    if (hasModules && hasModules.length == roles.length) {
      return true;
    }

    return false;
  }

  /**
   * Retrieves the client settings.
   * If the client settings are already available, it returns the cached settings.
   * Otherwise, it retrieves the client settings from the authentication service or local storage.
   * If the client settings are not found in local storage, it sends a request to fetch them.
   * @returns The client settings.
   */
  private getClientSettings(): ClientSettings {
    if (this.clientSetting) {
      return this.clientSetting;
    }
    let client = this.authService.getAuthState();

    if (client.client) {
      this.clientSetting = client.client;
    }

    if (!client.client) {
      this.clientSetting = this.clientService.getClientSettingsFromStorage() ?? {
        client: '',
        roles: [],
        modules: [],
        promotion: [],
        userType: 'undefined',
      };

      if (this.clientSetting.client == '') {
        this.requestClient();
      }
    }

    return this.clientSetting!;
  }

  /**
   * Sends a request to retrieve client settings and updates the client state.
   * @returns The client settings.
   */
  requestClient() {
    return this.clientService.getClients(true).subscribe({
      next: (clients: ClientSettings[]) => {
        let client = this.clientService.getClientSettingsFromStorage();
        if (client) {
          let matched = clients.filter((n) => n.client == client?.client)[0];
          if (matched) {
            client.modules = matched.modules;
            client.roles = matched.roles;
            client.userType = matched.userType;
          }
        } else {
          client = clients[0];
        }
        this.authService.setClientState(client);
        this.clientSetting = client;
        return this.clientSetting;
      },
      error: () => {
        this.clientSetting = {
          client: '',
          roles: [],
          modules: [],
          promotion: [],
          userType: 'undefined',
        };
      },
      complete: () => {
        this.clientSettingSig.set(this.clientSetting);
      },
    });
  }
}
