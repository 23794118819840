<div class="footer">
  <div class="container mt-md-4">
    <div class="row justify-content-between">
      <div class="col-sm-4">
        <a href="https://arvato.com/" class="footer--logo" target="_blank">
          <img class="logo" src="/assets/img/arvato-ligth.png" alt="Arvato Logo" />
        </a>
      </div>

      <hr class="d-block d-md-none mt-3" />

      <div class="col-lg-4 col-md-6">
        <h4>{{ "core.footer.support-title" | translate }}</h4>
        {{ "core.footer.support-text" | translate }}
        <nav class="navbar" aria-label="footer-contact-navigation">
          <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let contact of contacts | async">
              <a href="mailto:{{ contact.email }}" class="nav-link">
                <em class="pi pi-envelope me-1 mt-1 email-icon"></em>{{ contact.email }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <hr />

    <div class="row justify-content-between">
      <div class="col-sm-6">
        <div class="legal">
          <nav class="navbar navbar-expand-md" aria-label="footer-legal-navigation">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['privacy']">Privacy Policy</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://arvato.com/en/terms-and-conditions" target="_blank">{{
                  "core.footer.terms-of-use" | translate
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://arvato.com/en/imprint" target="_blank">
                  {{ "core.footer.imprints" | translate }}<em class="pi pi-arrow-up-right link--icon ms-1 mt-1"></em
                ></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://arvato.com/en" target="_blank">
                  Arvato.com<em class="pi pi-arrow-up-right link--icon ms-1 mt-1"></em
                ></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <hr class="d-block d-md-none mt-2" />

      <div class="col-lg-4 col-md-6 copyright">
        <div class="d-flex w-100 pr--16 copyright--content">Version {{ versionNumber }} · Copyright {{ currentYear }} Arvato SE</div>
      </div>
    </div>
  </div>
</div>
